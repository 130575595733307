import { render, staticRenderFns } from "./FaqAddForm.vue?vue&type=template&id=0806f880&scoped=true&"
import script from "./FaqAddForm.vue?vue&type=script&lang=js&"
export * from "./FaqAddForm.vue?vue&type=script&lang=js&"
import style0 from "./FaqAddForm.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./FaqAddForm.vue?vue&type=style&index=1&id=0806f880&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0806f880",
  null
  
)

export default component.exports